import Link from "next/link";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import ApiService from "@/utils/ApiServices";
import ApiUrl from "@/utils/ApiUrl";
import { loginAction } from '@/redux/action/AuthAction';
import { toast } from "react-toastify";
import { useRouter } from "next/router";
import GoogleLoginButton from "@/components/GoogleLoginButton";
import Cookies from "js-cookie";


const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);
  const router = useRouter();
  const [forgetPassword, setForgetPassword] = useState(false);

  const verifyEmail = async (e) => {
    e.preventDefault();
    try {
      const data = {
        email: email,
      }
      localStorage.setItem('user', JSON.stringify(data));
      const response = await ApiService.postApiService(ApiUrl.SEND_CODE_URL, { email: email, type: 'password' });
      if (response.statusCode === 200) {
        setSuccessMessage(response.message)
        setErrorMessage(null)
      } else if (response.statusCode === 400) {
        setSuccessMessage(null)
        setErrorMessage(response.message)
      }
    } catch (error) {
      const errorMsg = error.response?.data?.message || "Verification code sent failed";
      setErrorMessage(errorMsg);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "AUTH_REQUEST" });
    const redirect = router.query.redirect || '/';

    try {
      const response = await ApiService.postApiService(ApiUrl.LOGIN_URL, {
        email,
        password,
      });
      if (response.status === 'success') {

        const { user, token } = response.data;
        dispatch(loginAction(user, token));
        Cookies.set("userInfo", JSON.stringify(response.data));

        toast.success(response.message || 'Registration successful! Redirecting to course page...', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // router.push(redirect);
        
        if (window.history.length > 2 ) {
          router.back();
        } else {
          router.push(redirect);
        }
      }
    } catch (error) {
      const errorMsg = error.response?.data?.message || "Login failed";
      setErrorMessage(errorMsg);
      dispatch({
        type: "AUTH_FAILURE",
        payload: errorMsg,
      });
    }
  };
   return (
    <>
      {!forgetPassword ? (
        <div className="d-flex justify-content-center">
          <div className="col-lg-6">
            <div className="rbt-contact-form contact-form-style-1 max-width-auto">
              <h3 className="title">Login</h3>
              <div className="form-submit-group mb-4">
                <GoogleLoginButton />
              </div>
              <form className="max-width-auto" onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    name="con_name"
                    type="text"
                    placeholder="Email *"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <span className="focus-border"></span>
                </div>
                <div className="form-group">
                  <input
                    name="con_email"
                    type="password"
                    placeholder="Password *"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span className="focus-border"></span>
                </div>

                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}


                <div className="row mb--30">
                  <div className="col-lg-6">
                    <Link className="rbt-btn-link" href="/signup">
                      Don&apos;t have an account?
                    </Link>
                  </div>
                  <div className="col-lg-6">
                    <div className="rbt-lost-password text-end">
                      <Link className="rbt-btn-link" onClick={() => setForgetPassword(!forgetPassword)} href=''>
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="form-submit-group">
                  <button
                    type="submit"
                    className="rbt-btn btn-md btn-gradient hover-icon-reverse w-100"
                  >
                    <span className="icon-reverse-wrapper">
                      <span className="btn-text">Log In</span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                    </span>
                  </button>
                </div>
              </form>
              <h1></h1>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <div className="col-lg-6">
            <div className="rbt-contact-form contact-form-style-1 max-width-auto">
              <h3 className="title">Verify Email</h3>
              {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
              <form className="max-width-auto" onSubmit={verifyEmail}>
                <div className="form-group">
                  <input
                    name="con_name"
                    type="text"
                    placeholder="Email *"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} // Handle email input
                  />
                  <span className="focus-border"></span>
                </div>

                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}


                <div className="row mb--30">
                  <div className="col-lg-6">
                    <Link className="rbt-btn-link" href="/signup">
                      Don&apos;t have an account?
                    </Link>
                  </div>
                  <div className="col-lg-6">
                    <div className="rbt-lost-password text-end">
                      <Link className="rbt-btn-link" onClick={() => setForgetPassword(!forgetPassword)} href=''>
                        Go back to login
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="form-submit-group">
                  <button
                    type="submit"
                    className="rbt-btn btn-md btn-gradient hover-icon-reverse w-100"
                  >
                    <span className="icon-reverse-wrapper">
                      <span className="btn-text">Reset Password</span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;