import Login from "@/components/Login/Login";
import Context from "@/context/Context";
import PageHead from "@/pages/Head";
import Store from "@/redux/store";
import React from "react";
import { Provider } from "react-redux";


const LoginPage = () => {
  return (
    <>
      <PageHead title="Login & Register - Online Courses & Education NEXTJS14 Template" />
      <Provider store={Store}>
        <Context>
          <div className="rbt-elements-area bg-color-white rbt-section-gap">
            <div className="container">
                <div style={{marginTop: "-100px"}} className="row gy-5 row--30">
                    <Login/>
                </div>
            </div>
          </div>
        </Context>
      </Provider>
    </>
  );
};



// Server-side function to secure the route
export async function getServerSideProps({ req }) {
  const userInfo = req.cookies?.userInfo;

  if (userInfo) {
    // Redirect authenticated users to the home page
    return {
      redirect: {
        destination: "/",
        permanent: false,
      },
    };
  }

  // Allow unauthenticated users to access the login page
  return {
    props: {},
  };
}

export default LoginPage;
